import { NgModule } from '@angular/core';
import { BrowserModule} from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,  IonicModule.forRoot(), 
    RouterModule.forRoot([]), AppRoutingModule, HttpClientModule, SharedModule, 
    NgxCaptchaModule    ,

  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
